import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MiniHero from "../components/mini-hero"
import AboutMore from "../components/about-more"
import Quotes from "../components/quote"

const description  = 'Thinking about working with us ? We will soon be publishing more vacancies and opportunities on or website. Feel free to send us a CV or come into one of our offices.'
const heading ='What we offer'
const pagedescription = 'Our ethos, Work Life Balance'
const detail = `Here at paperten, we believe everyone is on a journey and we are here to be a part of it. Whatever you want to be, we would do our best to ensure that we support one another. We don't want you to just work for us, we want us to work together so we can achieve what we want to achieve and you achieve yours in the process.`

export default () => (
    <Layout>
        <SEO title="Join us for your growth is the heart of our business." description={description} keywords={[`Career`, `People-minded`, `Business`, `Culture`, `Diversity`,]} />
        <MiniHero title='Your Career' />
        <AboutMore title='Your Career' heading={heading} description={pagedescription} detail={detail} />
        <div class="whole-wrap">
		<div class="container box_1170">
        <Quotes />
        </div>
        </div>
    </Layout>

    );
